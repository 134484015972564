/////////////////////////////////////////////////////////////////////////////////
// Resume.js
// Resume components
/////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////
// Imports
/////////////////////////////////////////////////////////////////////////////////

import React from "react";
import { Document, Page, pdfjs } from 'react-pdf'
import '../css/App.css';
import Resume from '../pdf/resume.pdf';

/////////////////////////////////////////////////////////////////////////////////
// Constants
/////////////////////////////////////////////////////////////////////////////////

const worker = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const size = window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth;

/////////////////////////////////////////////////////////////////////////////////
// Functions
/////////////////////////////////////////////////////////////////////////////////

const Resume_template = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = worker;
  return (
    <div className='FlexBox Main Main_delay'>
      <p className='Text Paragraph'></p>
      <p className='Text Info'>Please refresh if this resume does not fit your screen</p>
      <Document file={Resume}>
        <Page pageNumber={1} width={size - 20}/>
      </Document>
    </div>
  );
}

export default Resume_template;
