/////////////////////////////////////////////////////////////////////////////////
// Notfound.js
// Notfound components
// Uses css styling from resume.css
/////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////
// Imports
/////////////////////////////////////////////////////////////////////////////////

import React from "react";

/////////////////////////////////////////////////////////////////////////////////
// Functions
/////////////////////////////////////////////////////////////////////////////////

const Notfound = () => {
  return (
    <div className='FlexBox Main'>
      <text className='Title Error'>404 Error</text>
      <text className='Text Paragraph'>The current URL was not found.</text>
    </div>
  );
}

export default Notfound;
